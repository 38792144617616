import React from "react";
import "./PowerGrounding.css";
//
import { useEffect } from "react";
//ant design
import { Carousel } from "antd";
//mui
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
//import test_img from "../../../assets/Map_marker.png";
import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
import install_icon from "../../../assets/services/medical_service/icons/install.png";
import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/powergrounding/img1.jpg";
import img2 from "../../../assets/services/medical_service/img2.jpg";
import img3 from "../../../assets/services/medical_service/img3.jpg";
import img4 from "../../../assets/services/medical_service/img4.jpg";
import img5 from "../../../assets/services/medical_service/img5.jpg";
// import img6 from "../../../assets/services/medical_service/img6.jpg";
// import img7 from "../../../assets/services/medical_service/img7.jpg";
//
// import simg1 from "../../../assets/services/medical_service/img1.jpg";
// import simg2 from "../../../assets/services/medical_service/img2.jpg";
// import simg3 from "../../../assets/services/medical_service/img3.jpg";
// import simg4 from "../../../assets/services/medical_service/img4.jpg";
//

// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   {
//     img: img6,
//     title: "Honey",
//     author: "@arwinneil",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img7,
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
// ];
//

const PowerGrounding = () => {
  //
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>Site Preparation and Consulting Service</h2>
        <p>
          Ensure stable power and grounding for uninterrupted healthcare
          operations. Our biomedical engineers deliver comprehensive solutions,
          including system assessments, grounding design, and maintenance
          services. Rely on our expertise to safeguard your facility's power
          reliability and safety standards.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Assessment and Design of Power Distribution Systems</h3>
            </div>
            <p>
              Optimize power distribution with thorough assessment and expert
              design services. We ensure efficient and reliable electricity
              supply throughout critical areas of your facility, tailored to
              meet specific operational needs and safety standards.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Installation and maintenance of backup power systems</h3>
            </div>
            <p>
              Ensure uninterrupted power supply with expert installation and
              proactive maintenance of backup power systems such as generators
              and uninterruptible power supplies (UPS). Our services guarantee
              continuous operation during power outages, safeguarding critical
              healthcare operations.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={install_icon} alt="alt" />
              <h3>
                Grounding system design and implementation to ensure electrical
                safety
              </h3>
            </div>
            <p>
              Enhance electrical safety with expert grounding system design and
              implementation services. We ensure compliance with safety
              standards and mitigate electrical hazards, safeguarding personnel,
              patients, and equipment from potential risks.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={uninstall_icon} alt="alt" />
              <h3>Power quality analysis and improvement solutions</h3>
            </div>
            <p>
              Optimize power quality with comprehensive analysis and improvement
              solutions. Our services include identifying and resolving power
              quality issues to enhance reliability and efficiency within
              healthcare environments, supporting uninterrupted operation of
              medical equipment.
            </p>
          </div>
        </div>

        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
          <img className="CarouselContainerImg" src={img5} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default PowerGrounding;
