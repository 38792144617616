import React, { useState } from "react";
import "./Contact.css";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { SendMail } from "./Mailer";
//ant design
import { Spin } from "antd";
//mui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
//map modules
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
//
import markericon from "../../assets/Map_marker.png";
import L from "leaflet";
//img
import contactusImg from "../../assets/contactus/contact_us.svg";
//

const customIcon = L.icon({
  iconUrl: markericon,
  iconSize: [32, 32], // Set the size of the icon
  iconAnchor: [16, 32], // Set the anchor point of the icon,
});
//import L, { Handler } from "leaflet";
//
//import Icon from "../../assets/logo.png";
//

const Contact = () => {
  //spin state
  const [loading, setLoading] = useState(false);
  //
  const notifyError = (msg) =>
    toast.error(`${msg}`, { position: "top-center", theme: "colored" });
  const notifySuccess = (msg) =>
    toast.success(`${msg}`, { position: "top-center", theme: "colored" });
  //
  const [FirstName, setFirstName] = useState("");
  //const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Discription, setDiscription] = useState("");
  //
  //const companyLat = 37.7749;
  //const companyLng = -122.4194;
  //
  const HandleSendMail = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      var fcall = await SendMail(FirstName, Email, Discription, Phone);
      //console.log(fcall);
      notifySuccess(fcall.data);
    } catch (error) {
      console.log(error);
      // notifyError(error.response.data);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        
        //console.log(error.response.data);
        notifyError(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };
  //
  //
  return (
    <div className="Contact">
      <ToastContainer />
      <Spin spinning={loading}>
        <div className="ContactUS_RL">
          <div className="ContactRight">
            <img
              className="contactusImg"
              src={contactusImg}
              alt="ContactusImg"
            ></img>
          </div>

          <div className="ContactLeft">
            <div className="ContactGreeting">
              <h2>Contact US</h2>
              <pre className="contactpre">
                "Have a question or ready to get started? Contact us now! Our
                team is here to assist you every step of the way. Let's turn
                your ideas into reality together."
              </pre>
            </div>
            <form onSubmit={HandleSendMail} className="contactForm">
              <div>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="filled-basic"
                    label="Full Name"
                    variant="filled"
                    type="text"
                    onChange={(event) => setFirstName(event.target.value)}
                    required
                  />
                  {/* <TextField
                  id="filled-basic"
                  label="Last Name"
                  variant="filled"
                  type="text"
                  onChange={(event) => setLastName(event.target.value)}
                  required
                /> */}
                </Box>
              </div>
              <div>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    id="fullWidth"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </Box>
              </div>
              <div>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Phone"
                    id="fullWidth"
                    type="tel"
                    onChange={(event) => setPhone(event.target.value)}
                    placeholder="Optional"
                  />
                </Box>
              </div>
              <div>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="filled-multiline-static"
                      label="Discription"
                      multiline
                      rows={4}
                      variant="filled"
                      onChange={(event) => setDiscription(event.target.value)}
                    />
                  </div>
                </Box>
              </div>
              <div>
                <Stack direction="row" spacing={2}>
                  <Button
                    className="Button"
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    Send
                  </Button>
                </Stack>
              </div>
            </form>
          </div>
        </div>
      </Spin>
      <div className="ContactUS_MAP" style={{ width: "100%", height: "600px" }}>
        <MapContainer
          center={[9.021019, 38.795304]}
          zoom={13}
          // style={{ width: "600px", height: "600px" }}
          style={{ width: "100%", height: "100%" }}
          // className="mapContainer"
          dragging={true}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          attributionControl={true}
          zoomControl={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[9.021019, 38.795304]} icon={customIcon}>
            <Popup>We are Here</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default Contact;
