//import React from "react";
import axios from "axios";

const SendMail = async (fname, email, body, phone) => {
  //alert(`${fname}"\n"${lname}"\n"${email}"\n"${body}`);
  try {
    // axios
    //   .get("https://localhost:3002/api/mts/sendMail")
    //   .then(function (response) {
    //     // handle success
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   })
    //   .finally(function () {
    //     // always executed
    //   });
    var request = await axios.post(`${process.env.REACT_APP_API_SEND_SUPPORT}`, {
      // firstName: fname,
      // lastName: lname,
      SenderAddress: email,
      SenderName: fname,
      SUBJECT: body,
    });
    console.log(request);
    return request;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { SendMail };
