import React, { useState } from "react";
import "./Navbar.css";
//
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
//
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
//social icons
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
//import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import logo from "../../assets/logo.png";
//

const Navbar = () => {
  const location = useLocation();
  //
  // Function to scroll to a specific element when using react-scroll
  const scrollToElement = (elementId) => {
    ScrollLink(elementId, {
      duration: 500,
      offset: -70, // Adjust offset as needed
      smooth: true,
    });
  };
  //
  // Check if current route is the landing page
  const isLandingPage = location.pathname === "/";
  //
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  //
  return (
    <div className="Navbar">
      <div className="Navbar_Top">
        <div className="nbleft">
          <div className="nbleftp1">
            <CallIcon />
            <div>
              <h4>(+251)92333931</h4>
            </div>
          </div>
        </div>
        <div className="nbleftp1">
          <EmailIcon />
          <div>
            <h4>info@multitech-et.com</h4>
          </div>
        </div>
        <div className="nbright">
          <a href="https://twitter.com/?lang=en">
            <XIcon />
          </a>
          <a href="https://facebook.com">
            <FacebookOutlinedIcon />
          </a>
          <a href="https://youtube.com">
            <YouTubeIcon />
          </a>
          <a href="https://www.linkedin.com/">
            <LinkedInIcon />
          </a>
        </div>
      </div>
      <div className="Navbar_Bottom">
        <div className="CompanyLogoDiv">
          <img className="logo" src={logo} alt="" />
          <div class="wrapper">
            {/* <svg>
              <text x="50%" y="50%" dy=".1em" text-anchor="middle">
                Multi Tech Technology Solutions PLC
              </text>
            </svg> */}
            <h1 className="CompanyName">
              Multi Tech Technology Solutions Trading PLC
            </h1>
          </div>
        </div>
        <div className="NavbarMenu">
          <nav>
            <ul className="Navbar_ul">
              <li className="MenuList">
                {isLandingPage ? (
                  <ScrollLink
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                  >
                    <lable className="MenuLable">Home</lable>
                  </ScrollLink>
                ) : (
                  <Link
                    to="/"
                    onClick={() => scrollToElement("home")} // Scroll to 'home' section
                  >
                    <lable className="MenuLable">Home</lable>
                  </Link>
                )}
              </li>
              <li className="MenuList">
                {isLandingPage ? (
                  <ScrollLink
                    to="aboutus"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                  >
                    <lable className="MenuLable">About</lable>
                  </ScrollLink>
                ) : (
                  <Link to="/#aboutus">
                    <lable className="MenuLable">About</lable>
                  </Link>
                )}
              </li>
              <li className="MenuList">
                {isLandingPage ? (
                  <ScrollLink
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                  >
                    <lable className="MenuLable">Service</lable>
                  </ScrollLink>
                ) : (
                  <Link to="/#service">
                    <lable className="MenuLable">Service</lable>
                  </Link>
                )}
              </li>
              <li className="MenuList">
                {isLandingPage ? (
                  <ScrollLink
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                  >
                    <lable className="MenuLable">Team</lable>
                  </ScrollLink>
                ) : (
                  <Link to="/#team">
                    <lable className="MenuLable">Team</lable>
                  </Link>
                )}
              </li>
              <li className="MenuList">
                {isLandingPage ? (
                  <ScrollLink
                    to="faq"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                  >
                    <lable className="MenuLable">FAQ</lable>
                  </ScrollLink>
                ) : (
                  <Link to="/#faq">
                    <lable className="MenuLable">FAQ</lable>
                  </Link>
                )}
              </li>
              <li className="MenuList">
                {isLandingPage ? (
                  // <ScrollLink
                  //   to="contact"
                  //   spy={true}
                  //   smooth={true}
                  //   offset={-70}
                  //   duration={500}
                  // >
                  //   <lable className="MenuLable">Contact</lable>
                  // </ScrollLink>
                  <Link to="/contact">
                    <lable className="MenuLable">Contact</lable>
                  </Link>
                ) : (
                  <Link to="/contact">
                    <lable className="MenuLable">Contact</lable>
                  </Link>
                )}
              </li>
              {/* <li className="MenuList">
                <ScrollLink
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-70} // Adjust offset as needed
                  duration={500}
                >
                  Manufacturing
                </ScrollLink>
              </li> */}
            </ul>
          </nav>
        </div>
        {/*  */}
        <div className="DrawerDiv">
          <MenuOutlined className="MenuIcon" onClick={showDrawer} />
          {/* <Button type="primary" onClick={showDrawer}>
            Open
          </Button> */}
          <Drawer title="Menu" onClose={onClose} open={open}>
            <div className="mobileMenuSContainer">
              <ul className="DrawerMenu">
                <li>
                  {/* <ScrollLink
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose} // Close drawer on item click
                  >
                    Home
                  </ScrollLink> */}
                  <Link to="/" onClick={onClose}>
                    Home
                  </Link>
                </li>
                <li>
                  <ScrollLink
                    to="aboutus"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose}
                  >
                    About
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose}
                  >
                    Service
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose}
                  >
                    Team
                  </ScrollLink>
                </li>
                <li>
                  <ScrollLink
                    to="faq"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose}
                  >
                    FAQ
                  </ScrollLink>
                </li>
                <li>
                  {/* <ScrollLink
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust offset as needed
                    duration={500}
                    onClick={onClose}
                  >
                    Contact
                  </ScrollLink> */}
                  <Link to="/contact" onClick={onClose}>
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="mobileMenuIcons">
                <LinkedInIcon className="mNavbarIconns" />
                <EmailIcon className="mNavbarIconns" />
                <FacebookOutlinedIcon className="mNavbarIconns" />
              </div>
            </div>
          </Drawer>
        </div>
        {/*  */}
      </div>
      {/* <Outlet /> */}
    </div>
  );
};

export default Navbar;
