import React from "react";
import "./FAQ.css";
import { Collapse } from "antd";
//
import img from "../../assets/FAQ/questions.svg";
//

// const text = `
//   A dog is a type of domesticated animal.
//   Known for its loyalty and faithfulness,
//   it can be found as a welcome guest in many households across the world.
// `;
const items = [
  {
    key: "1",
    label: "What products or services does your company offer?",
    children: (
      <p>
        Answer: Our company specializes in providing a diverse range of
        products/services tailored to meet the needs of [industry/niche]. From
        [specific product/service] to [another specific product/service], we aim
        to cater to various requirements with top-notch quality and customer
        satisfaction in mind.
      </p>
    ),
  },
  {
    key: "2",
    label: "How can I contact your company for support or inquiries?",
    children: (
      <p>
        Answer: You can reach our support team via several channels: Phone: Call
        us at [phone number] during business hours. Email: Send us an email at
        [email address] for any inquiries or support needs. Contact Form: Fill
        out the contact form on our website, and we'll get back to you promptly.
      </p>
    ),
  },
  {
    key: "3",
    label: "What sets your company apart from competitors?",
    children: (
      <p>
        Answer: At [Company Name], we distinguish ourselves through: Innovation:
        Constantly introducing new solutions that redefine industry standards.
        Customer Focus: Tailoring our products/services to meet specific
        customer needs. Quality Assurance: Commitment to delivering
        products/services of the highest quality.
      </p>
    ),
  },
  {
    key: "4",
    label: "Can I request a custom solution or product?",
    children: (
      <p>
        Answer: Absolutely! We understand that each customer's needs may vary.
        Our team is equipped to discuss your requirements and create custom
        solutions that align with your specific needs and objectives. Contact us
        to discuss your project in detail.
      </p>
    ),
  },
  {
    key: "5",
    label: "How can I stay updated with company news and announcements",
    children: (
      <p>
        Answer: Stay informed about our latest developments, product launches,
        and company news by: Subscribing to our newsletter via our website.
        Following our social media channels (links provided) for real-time
        updates. Checking our blog section regularly for insightful articles and
        updates.
      </p>
    ),
  },
];

const FAQ = () => {
  //
  const onChange = (key) => {
    console.log(key);
  };
  //
  return (
    <div className="FAQMain">
      <div className="FAQLeft">
        <h2>Your Questions, Our Answers</h2>
        <img src={img} alt="alt"></img>
      </div>

      <Collapse items={items} defaultActiveKey={["1"]} onChange={onChange} />
    </div>
  );
};

export default FAQ;
