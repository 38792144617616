import React from "react";
import "./ITSoftwareSolution.css";
//ant design
import { Carousel } from "antd";
//
import { useEffect } from "react";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
//import test_img from "../../../assets/Map_marker.png";
import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
import install_icon from "../../../assets/services/medical_service/icons/install.png";
//import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/medical_service/img1.jpg";
import img2 from "../../../assets/services/medical_service/img2.jpg";
import img3 from "../../../assets/services/medical_service/img3.jpg";
import img4 from "../../../assets/services/medical_service/img4.jpg";
// import img5 from "../../../assets/services/medical_service/img5.jpg";
// import img6 from "../../../assets/services/medical_service/img6.jpg";
// import img7 from "../../../assets/services/medical_service/img7.jpg";

// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   {
//     img: img6,
//     title: "Honey",
//     author: "@arwinneil",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img7,
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
// ];
//

const ITSoftwareSolution = () => {
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>IT Solutions</h2>
        <p>
          Optimize healthcare IT with our specialized solutions. Leveraging
          biomedical expertise, our engineers provide robust IT and software
          services tailored to healthcare needs. From system integration to
          cybersecurity, trust us to enhance your operational efficiency while
          safeguarding patient data.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Integration of electronic medical record (EMR) systems</h3>
            </div>
            <p>
              Seamlessly integrate electronic medical record (EMR) systems into
              your healthcare environment to streamline data management and
              enhance patient care coordination. Our expert integration services
              ensure compatibility and efficiency across all departments.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>
                Development and implementation of healthcare-specific software
                solutions
              </h3>
            </div>
            <p>
              Enhance operational efficiency with tailored software solutions
              designed for healthcare needs. From patient management to
              scheduling software, our solutions optimize workflows, improve
              resource allocation, and elevate patient engagement.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={install_icon} alt="alt" />
              <h3>
                IT infrastructure design and optimization for hospitals and
                clinics.
              </h3>
            </div>
            <p>
              Seamless integration is key to equipment performance. Our
              installation and configuration service guarantees expert setup,
              ensuring new additions align perfectly with existing systems for
              optimal functionality from day one.
            </p>
          </div>
        </div>

        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default ITSoftwareSolution;
