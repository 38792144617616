import React from "react";
import { useEffect } from "react";
import "./HospitalGasService";
//ant desin
import { Carousel } from "antd";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
// //
import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
// import test_img from "../../../assets/Map_marker.png";
// import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
// import install_icon from "../../../assets/services/medical_service/icons/install.png";
// import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/medical_service/img1.jpg";
import img2 from "../../../assets/services/medical_service/img2.jpg";
import img3 from "../../../assets/services/medical_service/img3.jpg";
import img4 from "../../../assets/services/medical_service/img4.jpg";
// import img5 from "../../../assets/services/medical_service/img5.jpg";
// import img6 from "../../../assets/services/medical_service/img6.jpg";
// import img7 from "../../../assets/services/medical_service/img7.jpg";
//
import simg1 from "../../../assets/services/hospital_gas/s1.png";
import simg2 from "../../../assets/services/hospital_gas/s2.png";
import simg3 from "../../../assets/services/hospital_gas/s3.png";
import simg4 from "../../../assets/services/hospital_gas/s2.png";
//

// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   {
//     img: img6,
//     title: "Honey",
//     author: "@arwinneil",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img7,
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
// ];
//

const HospitalGasService = () => {
  //
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  //
  return (
    <div className="MedicalService">
      <div className="MSThumbDiv">
        <img
          className="msthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="MSContent">
        <h2>Hospital Central Gas Station and Utility</h2>
        <p>
          Streamline hospital utility systems with our specialized services. Our
          biomedical engineers optimize central gas stations and utility setups,
          ensuring efficiency and compliance with safety standards. Count on us
          for reliable installations, inspections, and ongoing maintenance.
        </p>
        <h2>Our Hospital Central Gas Station and Utility Capabilities</h2>
        <div className="MSCapabilities">
          <div className="MSCapabilitie">
            <div className="header">
              <img src={simg1} alt="alt" />
              <h3>Installation and Maintenance of Medical Gas Systems</h3>
            </div>
            <p>
              Ensure reliable delivery of essential gases like oxygen and
              nitrogen with expert installation and meticulous maintenance of
              medical gas systems. Our services adhere to stringent safety
              standards, ensuring uninterrupted support for patient care.
            </p>
          </div>
          <div className="MSCapabilitie">
            <div className="header">
              <img src={simg2} alt="alt" />
              <h3>Centralized Utility Management</h3>
            </div>
            <p>
              Optimize hospital operations with centralized management of water,
              steam, and HVAC systems. We handle system design, installation,
              and ongoing management to enhance efficiency, reduce energy
              consumption, and maintain optimal conditions throughout the
              facility.
            </p>
          </div>
          <div className="MSCapabilitie">
            <div className="header">
              <img src={simg3} alt="alt" />
              <h3>Compliance Audits and Regulatory Adherence</h3>
            </div>
            <p>
              Ensure compliance with regulatory standards through thorough
              audits of hospital utility systems. Our services provide expert
              guidance and implementation to uphold safety standards, mitigate
              risks, and ensure continuous operations.
            </p>
          </div>
          <div className="MSCapabilitie">
            <div className="header">
              <img src={simg4} alt="alt" />
              <h3>Retrofitting and Upgrades of Utility Systems</h3>
            </div>
            <p>
              Enhance efficiency and safety with strategic retrofitting and
              upgrades of existing utility systems. From improving energy
              efficiency to integrating advanced technologies, our solutions are
              tailored to meet facility-specific needs and regulatory
              requirements for seamless healthcare operations.
            </p>
          </div>
        </div>

        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default HospitalGasService;
