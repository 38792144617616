import React from "react";
import "./medicalservice.css";
//ant desin
import { Carousel } from "antd";
//
import { useEffect } from "react";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
// import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
// import test_img from "../../../assets/Map_marker.png";
import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
import install_icon from "../../../assets/services/medical_service/icons/install.png";
import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/medical_service/img1.jpg";
import img2 from "../../../assets/services/medical_service/img2.jpg";
import img3 from "../../../assets/services/medical_service/img3.jpg";
import img4 from "../../../assets/services/medical_service/img4.jpg";
import img5 from "../../../assets/services/medical_service/img5.jpg";
import img6 from "../../../assets/services/medical_service/img6.jpg";
import img7 from "../../../assets/services/medical_service/img7.jpg";
import img8 from "../../../assets/services/medical_service/img8.jpg";
import img9 from "../../../assets/services/medical_service/img9.jpg";

const MedicalService = () => {
  //to make it start from the top

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src="https://www.oriontalent.com/field-service-careers/medical-technology-industry/images/medical-banner.jpg"
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>Medical Service and Support</h2>
        <p>
          Experience peace of mind with our comprehensive Medical Service and
          Support solutions, dedicated to ensuring the reliability and longevity
          of your vital medical equipment. From meticulous preventive
          maintenance to swift corrective interventions, our skilled technicians
          are adept at handling installations, deinstallations, and a plethora
          of other essential services. With a commitment to excellence and
          efficiency, we prioritize the seamless operation of your medical
          infrastructure, empowering you to focus on delivering exceptional
          patient care without disruptions.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Preventive Maintenance</h3>
            </div>
            <p>
              Regular upkeep is crucial for medical equipment longevity. Our
              preventive maintenance service ensures your machines remain in top
              condition, averting potential breakdowns and ensuring
              uninterrupted operations.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Corrective Maintenance</h3>
            </div>
            <p>
              When issues arise, prompt action is essential. Our corrective
              maintenance swiftly addresses malfunctions, restoring equipment
              functionality to minimize downtime and maintain workflow
              efficiency.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={install_icon} alt="alt" />
              <h3>Installation and Configuration</h3>
            </div>
            <p>
              Seamless integration is key to equipment performance. Our
              installation and configuration service guarantees expert setup,
              ensuring new additions align perfectly with existing systems for
              optimal functionality from day one.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={uninstall_icon} alt="alt" />
              <h3>Deinstallation and Removal</h3>
            </div>
            <p>
              Outdated equipment can clutter space and pose hazards. Our removal
              service efficiently clears out obsolete machinery, handling
              disposal responsibly to meet environmental regulations and free up
              valuable space.
            </p>
          </div>
        </div>
        <div className="CarouselContainerImgDIV">
          <h2>Image Gallery</h2>
          <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
            <img className="CarouselContainerImg" src={img2} alt="alt"></img>
            <img className="CarouselContainerImg" src={img1} alt="alt"></img>
            <img className="CarouselContainerImg" src={img3} alt="alt"></img>
            <img className="CarouselContainerImg" src={img4} alt="alt"></img>
            <img className="CarouselContainerImg" src={img5} alt="alt"></img>
            <img className="CarouselContainerImg" src={img6} alt="alt"></img>
            <img className="CarouselContainerImg" src={img7} alt="alt"></img>
            <img className="CarouselContainerImg" src={img8} alt="alt"></img>
            <img className="CarouselContainerImg" src={img9} alt="alt"></img>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default MedicalService;
