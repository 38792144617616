import React from "react";
import "./Team.css";
//import { Parallax } from "react-scroll-parallax";
//mui
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//avater
import maleAvater from "../../assets/maleAvater.jpg";
// import maleAvater2 from "../../assets/maleAvater.jpg";
// import maleAvater3 from "../../assets/maleAvater.jpg";
import femaleAvater from "../../assets/femaleAvater.jpg";
//
//import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
//
const Team = () => {
  return (
    <div className="Team">
      <div className="TeamHead">
        <h2>Meet Our Team of Professionals</h2>
      </div>
      <div className="TeamMembers">
        {/* <Parallax speed={-10}> */}
        <div className="TeamMember">
          <Card className="meamber-card" sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 200 }}
              image={femaleAvater}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Elsa Zeray
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Radiation Safety and Quality Control Expert
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => window.open("https://www.linkedin.com/")}
              >
                <LinkedInIcon />
              </Button>
              <Button
                size="small"
                onClick={() =>
                  window.open(`mailto:elsa.zeray@multitech-et.com`)
                }
              >
                <EmailIcon />
              </Button>
              <Button
                size="small"
                onClick={() => window.open(`tel:0953119305`)}
              >
                <LocalPhoneIcon />
              </Button>
            </CardActions>
          </Card>
        </div>
        {/* </Parallax> */}

        {/* <Parallax speed={10}> */}
        <div className="TeamMember">
          <Card className="meamber-card" sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 200 }}
              image={maleAvater}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Daniel Lemma
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Sales and Service Manager
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => window.open("https://www.linkedin.com/")}
              >
                <LinkedInIcon />
              </Button>
              <Button
                size="small"
                onClick={() =>
                  window.open(`mailto:daniel.lemma@multitech-et.com`)
                }
              >
                <EmailIcon />
              </Button>
              <Button
                size="small"
                onClick={() => window.open(`tel:0910365126`)}
              >
                <LocalPhoneIcon />
              </Button>
            </CardActions>
          </Card>
        </div>
        {/* </Parallax> */}

        {/* <Parallax speed={-10}> */}
        <div className="TeamMember">
          <Card className="meamber-card" sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 200 }}
              image={maleAvater}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Gemechis kaba
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Qualification
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => window.open("https://www.linkedin.com/")}
              >
                <LinkedInIcon />
              </Button>
              <Button
                size="small"
                // onClick={() =>
                //   window.open(`mailto:elsa.zeray@multitech-et.com`)
                // }
              >
                <EmailIcon />
              </Button>
              <Button
                size="small"
                // onClick={() => window.open(`tel:0910365126`)}
              >
                <LocalPhoneIcon />
              </Button>
            </CardActions>
          </Card>
        </div>
        {/* </Parallax> */}

        {/* <Parallax speed={10}> */}
        <div className="TeamMember">
          <Card className="meamber-card" sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 200 }}
              image={maleAvater}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Melkamu Eshetu
              </Typography>
              <Typography variant="body2" color="text.secondary">
                General Manager
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => window.open("https://www.linkedin.com/")}
              >
                <LinkedInIcon />
              </Button>
              <Button
                size="small"
                onClick={() =>
                  window.open(`mailto:melkamu.eshetu@multitech-et.com`)
                }
              >
                <EmailIcon />
              </Button>
              <Button size="small" onClick={() => window.open(`tel:0911886992`)}>
                <LocalPhoneIcon />
              </Button>
            </CardActions>
          </Card>
        </div>
        {/* </Parallax> */}

        {/* <Parallax speed={-10}> */}
        {/* <div className="TeamMember">
          <Card className="meamber-card" sx={{ maxWidth: 345 }}>
            <CardMedia
              sx={{ height: 200 }}
              image={femaleAvater}
              title="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Selam shewangizaw
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Qualification
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                onClick={(e) => window.open("https://www.linkedin.com/")}
              >
                <LinkedInIcon />
              </Button>
              <Button size="small">
                <EmailIcon />
              </Button>
              <Button size="small">
                <LocalPhoneIcon />
              </Button>
            </CardActions>
          </Card>
        </div> */}
        {/* </Parallax> */}
      </div>
    </div>
  );
};

export default Team;
