import React from "react";
import "./LABSolutions.css";
import { useEffect } from "react";
//ant desin
import { Carousel } from "antd";
//material ui
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
// import test_img from "../../../assets/Map_marker.png";
// import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
// import install_icon from "../../../assets/services/medical_service/icons/install.png";
// import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/lab/img1.jpg";
import img2 from "../../../assets/services/lab/img2.jpg";
import img3 from "../../../assets/services/lab/img3.jpg";
import img4 from "../../../assets/services/lab/img4.jpg";
// import img5 from "../../../assets/services/surgical/img5.jpg";
// import img6 from "../../../assets/services/surgical/img6.jpg";
// import img7 from "../../../assets/services/surgical/img7.jpg";
//
import simg1 from "../../../assets/services/surgical/s1.png";
import simg2 from "../../../assets/services/surgical/s1.png";
import simg3 from "../../../assets/services/surgical/s1.png";
//import simg4 from "../../../assets/services/surgical/s4.png";
//
//
// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   // {
//   //   img: img6,
//   //   title: "Honey",
//   //   author: "@arwinneil",
//   //   rows: 2,
//   //   cols: 2,
//   //   featured: true,
//   // },
//   // {
//   //   img: img7,
//   //   title: "Basketball",
//   //   author: "@tjdragotta",
//   // },
// ];
//

const LABSolutions = () => {
  //
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>LAB Solutions</h2>
        <p>
          At Multi-Tech Technology Solution and Trading PLC, our LAB Solutions
          are tailored to ensure your laboratory operates at peak efficiency. We
          offer expert maintenance to keep your equipment in top condition,
          precise calibration to guarantee accurate and reliable results, and
          comprehensive training to empower your team with the knowledge and
          skills they need. Our commitment to delivering dependable solutions
          supports your scientific endeavors and enhances the overall
          performance and integrity of your lab.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg1} alt="alt" />
              <h3>Maintenance</h3>
            </div>
            <p>
              Keep your lab equipment in top condition with our expert
              maintenance services, ensuring long-term reliability and
              efficiency.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg2} alt="alt" />
              <h3>Calibration</h3>
            </div>
            <p>
              Ensure precise and accurate results with our calibration services,
              designed to maintain the highest standards of measurement and
              performance.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg3} alt="alt" />
              <h3>Training</h3>
            </div>
            <p>
              Empower your team with comprehensive training, providing the
              knowledge and skills needed to maximize lab efficiency and
              effectiveness.
            </p>
          </div>
        </div>
        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default LABSolutions;
