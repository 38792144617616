import React from "react";
import "./Services.css";
//
import { useNavigate } from "react-router-dom";
//mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
//img
// import Medical_Icon from "../../assets/services/icons/healthcare.gif";
// import DigitalImage_Icon from "../../assets/services/icons/digitalimg.gif";
// import SurgicalLab_Icon from "../../assets/services/icons/surgery.gif";
// import ITSoftware_Icon from "../../assets/services/icons/software.png";
// import PowerGrounding_Icon from "../../assets/services/icons/unplugged.gif";
// import HospitalGas_Icon from "../../assets/services/icons/hospitalgas.png";
// import ElectroMechanical_Icon from "../../assets/services/icons/electromechanical.gif";
// import techIcon from "../../assets/services/icons/technology.gif";
// import labIcon from "../../assets/services/icons/lab.gif";
// import LABSolutions from "./Lab/LABSolutions";

//import installation_pic from "../../assets/services/installation1.jpg";
//import solution_pic from "../../assets/services/solutions1.jpg";
//pages
//import MedicalService from "./Medical/MedicalServoce";
//

const Services = () => {
  //methods
  const navigate = useNavigate();
  const ServiceNavigation = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  //
  return (
    <div className="Services">
      <div className="ServiceHeader">
        <h1 className="">Our Services</h1>
      </div>
      <div class="divpre">
        <pre className="servicepre">
          At Multi-Tech Technology Solution and Trading PLC, we take pride in
          offering a wide range of services that cater to various sectors.
          Whether you’re in manufacturing, healthcare, technology, or any other
          industry, our team is here to support your unique needs. Explore our
          services.
        </pre>
      </div>
      <div className="ServicesCards">
        <div className="ServiceCard1">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) => ServiceNavigation(event, "medicalservice")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                // width="100"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/egj09xcmzitkdyvr4wd9"
                alt="green iguana"
                
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {/* Site Preparation */}Medical Service and Suppport
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our dedicated medical professionals provide compassionate
                  care, accurate diagnoses, and personalized treatment plans.
                  From routine check-ups to emergency interventions, we
                  prioritize your well-being.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) => ServiceNavigation(event, "medicalservice")}
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="ServiceCard2">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) =>
              ServiceNavigation(event, "digitalimagesolution")
            }
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/rsxn8qgdwoumtg2tdnoi"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Digital Image Solution
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our cutting-edge digital imaging solutions transform medical
                  diagnostics. We offer seamless access to X-rays, MRIs, and CT
                  scans, aiding in precise diagnoses and telemedicine
                  consultations.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) =>
                  ServiceNavigation(event, "digitalimagesolution")
                }
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="ServiceCard3">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) => ServiceNavigation(event, "surgicallab")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/uaspq7itdyrb4valc70v"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {/* Deinstallation */}Surgical and LCS
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our skilled surgical team performs life-saving procedures,
                  while our advanced labs analyze samples for accurate disease
                  detection. Quality care meets precision science.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) => ServiceNavigation(event, "surgicallab")}
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="ServiceCard3">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) => ServiceNavigation(event, "itsoftwaresolution")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/kctyjj6jjklc7zqrdrrc"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {/* Post-Installation-Service */}IT and Software Solution
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our robust IT infrastructure ensures seamless hospital
                  management. From electronic health records (EHRs) to
                  telehealth platforms, we optimize healthcare delivery.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) =>
                  ServiceNavigation(event, "itsoftwaresolution")
                }
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>
        <div className="ServiceCard3">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) => ServiceNavigation(event, "powergrounding")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/ybck2ritthbuavso1ntn"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Site Preparation and Consulting Service
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Reliable power supply and proper grounding are essential. Our
                  electrical experts maintain safety, backup systems, and
                  efficient resource distribution.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) => ServiceNavigation(event, "powergrounding")}
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>

        {/* <div className="ServiceCard3">
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image={HospitalGas_Icon}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Hospital Central Gas Station and Utility
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our central gas station supplies medical gases (oxygen,
                  nitrogen, etc.) for patient care. Efficient utility management
                  ensures seamless operations.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) =>
                  ServiceNavigation(event, "hospitalgasservice")
                }
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div> */}

        <div className="ServiceCard3">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) =>
              ServiceNavigation(event, "electromecanicalservice")
            }
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/z674p37gfryllncal3vb"
                alt="Electro-Mechanical"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {/* Inspection */}Elctro-Mecahnical Technoloy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Our skilled technicians maintain critical medical equipment,
                  ensuring reliability and patient safety, while our central gas
                  station efficiently supplies essential medical gases like
                  oxygen and nitrogen. Together, we ensure seamless operations
                  and continuous care for patients.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) =>
                  ServiceNavigation(event, "electromecanicalservice")
                }
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>

        {/* <div className="ServiceCard3">
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={solution_pic}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Training
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                More
              </Button>
            </CardActions>
          </Card>
        </div> */}

        {/* <div className="ServiceCard3">
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="140"
                image={solution_pic}
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Consulting
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                More
              </Button>
            </CardActions>
          </Card>
        </div> */}
        <div className="ServiceCard3">
          <Card
            sx={{ maxWidth: 345 }}
            onClick={(event) => ServiceNavigation(event, "labsolutions")}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="300"
                image="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/servicesCardIcon/chej2pwvkvtadqyaae3q"
                alt="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  LAB Solutions
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Ensure peak performance in your lab with our expert
                  maintenance, calibration, and training services. Trustworthy
                  solutions for your scientific success.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(event) => ServiceNavigation(event, "surgicallab")}
              >
                More
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Services;
