import React from "react";
import "./ElectroMecanicalService.css";
import { useEffect } from "react";
//ant design
import { Carousel } from "antd";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
// import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
// import test_img from "../../../assets/Map_marker.png";
import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
import install_icon from "../../../assets/services/medical_service/icons/install.png";
import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/electromechanical/img1.jpg";
import img2 from "../../../assets/services/electromechanical/img2.jpg";
import img3 from "../../../assets/services/electromechanical/img3.jpg";
import img4 from "../../../assets/services/electromechanical/img4.jpg";
import img5 from "../../../assets/services/electromechanical/img5.jpg";

// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   {
//     img: img6,
//     title: "Honey",
//     author: "@arwinneil",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img7,
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
// ];
//

const ElectroMecanicalService = () => {
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src="https://wallpapers.com/images/hd/electronic-components-1332-x-850-wallpaper-fvs8uw6vnw28bv6g.jpg"
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>Electro-Mecanical</h2>
        <p>
          Maximize equipment performance with our electro-mechanical service
          expertise. Our biomedical engineers provide comprehensive support,
          from equipment installations to complex repairs. Trust our meticulous
          approach to ensure operational continuity and longevity of critical
          medical machinery.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Installation, Calibration, and Maintenance of Equipment</h3>
            </div>
            <p>
              Ensure optimal performance and longevity of critical equipment
              through expert installation, precise calibration, and proactive
              maintenance services. Our skilled technicians are trained to
              uphold rigorous standards, minimizing downtime and optimizing
              equipment functionality to support seamless operations in your
              industry.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={maintenance_icon} alt="alt" />
              <h3>Repair and Refurbishment of Devices</h3>
            </div>
            <p>
              Extend the lifespan of devices through expert repair and
              meticulous refurbishment. Our specialized services are designed to
              restore equipment to peak performance, reducing the need for
              costly replacements and ensuring continuous operational
              efficiency.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={install_icon} alt="alt" />
              <h3>Custom Equipment Modifications</h3>
            </div>
            <p>
              Tailor medical equipment to specific hospital needs with custom
              modifications. Our engineers collaborate closely with healthcare
              providers to design and implement solutions that enhance
              functionality, usability, and integration within existing
              infrastructures.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={uninstall_icon} alt="alt" />
              <h3>Technical Consulting and Advisory Services</h3>
            </div>
            <p>
              Make informed equipment decisions with our technical consulting
              and advisory services. We provide expert guidance on equipment
              selection, procurement strategies, and lifecycle management,
              empowering healthcare facilities to optimize investments and
              maintain cutting-edge technology.
            </p>
          </div>
        </div>

        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
          <img className="CarouselContainerImg" src={img5} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default ElectroMecanicalService;
