import React from "react";
import "./Brands.css";
//marquee
import Marquee from "react-fast-marquee";
//Brands
import BrandImg1 from "../../assets/brands/Carestream_Logo.png";
import BrandImg2 from "../../assets/brands/DRGEM_Logo.png";
import BrandImg3 from "../../assets/brands/storzmedical_Logo.png";
import BrandImg4 from "../../assets/brands/tuttnauer_Logo.png";
import BrandImg5 from "../../assets/brands/terumo.png";
import BrandImg6 from "../../assets/brands/Termo_Fisher.png";
import BrandImg7 from "../../assets/brands/Termo_BCT.png";
import BrandImg8 from "../../assets/brands/sturdy.png";
import BrandImg9 from "../../assets/brands/SFRI.png";
import BrandImg10 from "../../assets/brands/radiometer.png";
import BrandImg11 from "../../assets/brands/mindray.png";
import BrandImg12 from "../../assets/brands/karlstorz_de.png";
import BrandImg13 from "../../assets/brands/GE.png";
import BrandImg14 from "../../assets/brands/kls.png";
import BrandImg15 from "../../assets/brands/sonoscape-logo.png";
//

const Brands = () => {
  return (
    <div className="Brands">
      <div className="brand-header">
        <h1>Expertise in Leading Product Training</h1>
      </div>
      <div className="marquee-container">
        <Marquee>
          <img
            className="brand-img"
            src={BrandImg1}
            alt="Carestream Logo"
          ></img>
          <img className="brand-img" src={BrandImg2} alt="DRGEM Logo"></img>
          <img
            className="brand-img"
            src={BrandImg3}
            alt="Storz Medical Logo"
          ></img>
          <img className="brand-img" src={BrandImg4} alt="Tuttnauer Logo"></img>
          <img className="brand-img" src={BrandImg5} alt="Tuttnauer Logo"></img>
          <img className="brand-img" src={BrandImg6} alt="Tuttnauer Logo"></img>
          <img className="brand-img" src={BrandImg7} alt="Tuttnauer Logo"></img>
          <img className="brand-img" src={BrandImg8} alt="Tuttnauer Logo"></img>
          <img className="brand-img" src={BrandImg9} alt="Tuttnauer Logo"></img>
          <img
            className="brand-img"
            src={BrandImg10}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={BrandImg11}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={BrandImg12}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={BrandImg13}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={BrandImg14}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={BrandImg15}
            alt="Tuttnauer Logo"
          ></img>
        </Marquee>
      </div>
    </div>
  );
};

export default Brands;
