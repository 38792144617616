import React from "react";
import "./Partners.css";
//import { ParallaxBanner } from "react-scroll-parallax";
//marquee
import Marquee from "react-fast-marquee";
//
import peopleImg from "../../assets/partners/people.svg";
//Brands
//import PartnerImg1 from "../../assets/partners/betezatha_Logo.png";
//import PartnerImg2 from "../../assets/partners/girumhospital_Logo.png";
//import PartnerImg3 from "../../assets/partners/landmark_Logo.png";
//import PartnerImg4 from "../../assets/partners/yekatit12_Logo.png";
//import PartnerImg5 from "../../assets/partners/betezatha_Logo.png";
//import PartnerImg6 from "../../assets/partners/girumhospital_Logo.png";

//import PartnerImg8 from "../../assets/partners/yekatit12_Logo.png";
//
import PartnerLogo1 from "../../assets/partners/et.png";
import PartnerLogo2 from "../../assets/partners/imo.png";
import PartnerLogo3 from "../../assets/partners/yekatit12_Logo.png";
import PartnerLogo4 from "../../assets/partners/samaritan.png";
import PartnerLogo5 from "../../assets/partners/Ethio_istanbul.png";
import PartnerLogo6 from "../../assets/partners/landmark_Logo.png";
import PartnerLogo7 from "../../assets/partners/Afilas_G_Hospita-removebg-preview.png";

const Partners = () => {
  return (
    <div className="Partners">
      <div className="PartnerHeader">
        <h1 className="PartnerHeader_label">Our Partners</h1>
      </div>
      <div className="PartnersPreDiv">
        <pre className="PartnersPre">
          Interested in partnering with us? We're always on the lookout for
          collaborative opportunities to drive mutual success. Whether you're a
          technology provider, healthcare organization, or industry innovator,
          let's explore how we can combine our strengths to deliver impactful
          solutions. Contact us today to discuss partnership possibilities and
          unlock new avenues of growth together.
        </pre>
        <img className="partnersImg" src={peopleImg} alt="alt"></img>
      </div>
      {/* <div>
        <Marquee direction={"right"}>
          <img className="PartnerImg" src={PartnerImg1}></img>
          <img className="PartnerImg" src={PartnerImg2}></img>
          <img className="PartnerImg" src={PartnerImg3}></img>
          <img className="PartnerImg" src={PartnerImg4}></img>
          <img className="PartnerImg" src={PartnerImg5}></img>
          <img className="PartnerImg" src={PartnerImg6}></img>
          <img className="PartnerImg" src={PartnerImg7}></img>
          <img className="PartnerImg" src={PartnerImg8}></img>
        </Marquee>
      </div> */}
      {/* <div className="partners_container">
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo1}></img>
          <h3 className="partner_name">Ethiopian Airlines</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo2}></img>
          <h3 className="partner_name">
            International Organization for Migration
          </h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo3}></img>
          <h3 className="partner_name">Yekatit 12 Hospital</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo4}></img>
          <h3 className="partner_name">Samaritan Surgical Center Plc</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo5}></img>
          <h3 className="partner_name">Ethio-Istanbul</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo6}></img>
          <h3 className="partner_name">Land Mark Hospital</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
        <div className="partners_card">
          <img className="partner_logo" src={PartnerLogo7}></img>
          <h3 className="partner_name">Afilias General Hospital</h3>
          <pre className="partner_note">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </pre>
        </div>
      </div> */}
      <div className="marquee_container">
        <Marquee direction="right">
          <img
            className="brand-img"
            src={PartnerLogo1}
            alt="Carestream Logo"
          ></img>
          <img className="brand-img" src={PartnerLogo2} alt="DRGEM Logo"></img>
          <img
            className="brand-img"
            src={PartnerLogo3}
            alt="Storz Medical Logo"
          ></img>
          <img
            className="brand-img"
            src={PartnerLogo4}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={PartnerLogo5}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={PartnerLogo6}
            alt="Tuttnauer Logo"
          ></img>
          <img
            className="brand-img"
            src={PartnerLogo7}
            alt="Tuttnauer Logo"
          ></img>
        </Marquee>
      </div>
    </div>
  );
};

export default Partners;
