import React from "react";
import "./App.css";
//
import { BrowserRouter, Routes, Route } from "react-router-dom";
//
//import { Parallax } from "react-scroll-parallax";
//components
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

//pages and components
import Home from "./Pages/Home/Home";
import MedicalService from "./Pages/Services/Medical/MedicalServoce";
import DigitalImageSolution from "./Pages/Services/DigitalImageSolution/DigitalImageSolution";
import SurgicalLab from "./Pages/Services/SurgicalLab/SurgicalLab";
import ITSoftwareSolution from "./Pages/Services/ITSoftwareSolution/ITSoftwareSolution";
import PowerGrounding from "./Pages/Services/PowerGrounding/PowerGrounding";
import HospitalGasService from "./Pages/Services/HospitalGasService/HospitalGasService";
import ElectroMecanicalService from "./Pages/Services/ElectroMecanicalService/ElectroMecanicalService";
import LABSolutions from "./Pages/Services/Lab/LABSolutions";
import Contact from "./Pages/Contact/Contact";
//

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="NavDiv">
          <Navbar />
        </div>
        <div className="App_Body">
          <Routes>
            <Route index path="/" element={<Home />} />

            <Route path="/contact" element={<Contact />} />

            <Route path="/medicalservice" element={<MedicalService />} />
            <Route
              path="digitalimagesolution"
              element={<DigitalImageSolution />}
            />
            <Route path="/surgicallab" element={<SurgicalLab />} />
            <Route
              path="/itsoftwaresolution"
              element={<ITSoftwareSolution />}
            />
            <Route path="/powergrounding" element={<PowerGrounding />} />
            <Route
              path="/hospitalgasservice"
              element={<HospitalGasService />}
            />
            <Route
              path="/electromecanicalservice"
              element={<ElectroMecanicalService />}
            />

            <Route path="/labsolutions" element={<LABSolutions />} />
          </Routes>
        </div>

        <div className="FooterDiv">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
