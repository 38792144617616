import React from "react";
import "./Testimonial.css";
//
import { Carousel } from "antd";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
//
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
///import TestImg from "../../assets/Map_marker.png";
//

// const contentStyle = {
//   margin: 0,
//   height: "160px",
//   color: "#fff",
//   lineHeight: "160px",
//   textAlign: "center",
//   background: "#364d79",
// };

const Testimonial = () => {
  return (
    <div className="Testimonial">
      <>
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <div className="TestimonialContainer">
            <div className="TestimonialContent">
              <div className="TestimonialBody">
                <h3 className="TestimonialHeader">Testimonial Header</h3>
                <p className="TestimonialMessage">
                  Working with Multi-Tech Solutions has been a game-changer for
                  our company. Their team's expertise in software development
                  helped us streamline our operations and improve efficiency
                  across the board. I highly recommend their services to any
                  organization seeking innovative tech solutions.
                </p>
              </div>
              <div className="TestimonialPerson">
                <Avatar size="large" icon={<UserOutlined />} />
                <h2>Sarah Johnson</h2>
                <h3>CEO - Tech Innovations Inc</h3>
              </div>
            </div>
          </div>

          <div className="TestimonialContainer">
            <div className="TestimonialContent">
              <div className="TestimonialBody">
                <h3 className="TestimonialHeader">Testimonial Header</h3>
                <p className="TestimonialMessage">
                  Working with Multi-Tech Solutions has been a game-changer for
                  our company. Their team's expertise in software development
                  helped us streamline our operations and improve efficiency
                  across the board. I highly recommend their services to any
                  organization seeking innovative tech solutions.
                </p>
              </div>
              <div className="TestimonialPerson">
                <Avatar size="large" icon={<UserOutlined />} />
                <h2>Sarah Johnson</h2>
                <h3>CEO - Tech Innovations Inc</h3>
              </div>
            </div>
          </div>

          <div className="TestimonialContainer">
            <div className="TestimonialContent">
              <div className="TestimonialBody">
                <h3 className="TestimonialHeader">Testimonial Header</h3>
                <p className="TestimonialMessage">
                  Working with Multi-Tech Solutions has been a game-changer for
                  our company. Their team's expertise in software development
                  helped us streamline our operations and improve efficiency
                  across the board. I highly recommend their services to any
                  organization seeking innovative tech solutions.
                </p>
              </div>
              <div className="TestimonialPerson">
                <Avatar size="large" icon={<UserOutlined />} />
                <h2>Sarah Johnson</h2>
                <h3>CEO - Tech Innovations Inc</h3>
              </div>
            </div>
          </div>
        </Carousel>
      </>
    </div>
  );
};

export default Testimonial;

// {
  /* <Carousel
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        axis="vertical"
        showArrows={false}
        showIndicators={false}
      >
        <div className="testimonialCarousel">
          <hr />
          <p className="testimonial-text">
            <span>&#8243;</span>Working with Multi-Tech Solutions has been a
            game-changer for our company. Their team's expertise in software
            development helped us streamline our operations and improve
            efficiency across the board. I highly recommend their services to
            any organization seeking innovative tech solutions.
            <span>&#8243;</span>
          </p>
          <hr />
          <p className="testimonial-author">
            - Sarah Johnson - CEO - Tech Innovations Inc
          </p>
        </div>
        <div className="testimonial">
          <p className="testimonial-text">
            <span>&#8243;</span>Multi-Tech Solutions exceeded our expectations
            with their exceptional IT support services. Their proactive approach
            to solving problems and their quick response time have been
            invaluable to our business. I'm truly impressed with their
            professionalism and dedication to customer satisfaction.
            <span>&#8243;</span>
          </p>
          <p className="testimonial-author">
            - Michael Chen - Director of IT - Global Enterprises Ltd
          </p>
        </div>
        <div className="testimonial">
          <hr />
          <p className="testimonial-text">
            <span>&#8243;</span>As a fellow technology leader, I can confidently
            say that Multi-Tech Solutions sets the standard for excellence in
            the industry. Their team's deep knowledge of emerging technologies
            and their ability to tailor solutions to our specific needs have
            been instrumental in driving our company's success.
            <span>&#8243;</span>
          </p>
          <hr />
          <p className="testimonial-author">
            - Emily Rodriguez - CTO - Digital Dynamics LLC
          </p>
        </div>
        <div className="testimonial">
          <hr />
          <p className="testimonial-text">
            <span>&#8243;</span>Multi-Tech Solutions has been an indispensable
            partner in our journey to develop cutting-edge products. Their
            expertise in hardware and software engineering, coupled with their
            commitment to delivering high-quality results, has enabled us to
            stay ahead of the competition. I highly recommend their services to
            anyone looking for top-notch tech solutions.<span>&#8243;</span>
          </p>
          <hr />
          <p className="testimonial-author">
            - David Lee - VP of Engineering - Innovative Technologies Co
          </p>
        </div>
        <div className="testimonial">
          <hr />
          <p className="testimonial-text">
            <span>&#8243;</span>Multi-Tech Solutions has been instrumental in
            helping us achieve our marketing goals through their innovative
            digital solutions. Their team's creativity and attention to detail
            have significantly boosted our online presence and brand visibility.
            I'm incredibly impressed with their professionalism and expertise.
            <span>&#8243;</span>
          </p>
          <hr />
          <p className="testimonial-author">
            - Jennifer Patel - Chief Marketing Officer - Visionary Ventures Ltd
          </p>
        </div>
      </Carousel> */
// }
