import React from "react";
import "./SurgicalLab.css";
import { useEffect } from "react";
//ant desin
import { Carousel } from "antd";
//material ui
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
import ms_thumb from "../../../assets/services/medical_service/img1.jpg";
// import test_img from "../../../assets/Map_marker.png";
// import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
// import install_icon from "../../../assets/services/medical_service/icons/install.png";
// import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/surgical/img1.jpg";
import img2 from "../../../assets/services/surgical/img2.jpg";
import img3 from "../../../assets/services/surgical/img3.jpg";
import img4 from "../../../assets/services/surgical/img4.jpg";
import img5 from "../../../assets/services/surgical/img5.jpg";
// import img6 from "../../../assets/services/surgical/img6.jpg";
// import img7 from "../../../assets/services/surgical/img7.jpg";
//
import simg1 from "../../../assets/services/surgical/s1.png";
import simg2 from "../../../assets/services/surgical/s1.png";
import simg3 from "../../../assets/services/surgical/s1.png";
import simg4 from "../../../assets/services/surgical/s4.png";
//
//
// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   // {
//   //   img: img6,
//   //   title: "Honey",
//   //   author: "@arwinneil",
//   //   rows: 2,
//   //   cols: 2,
//   //   featured: true,
//   // },
//   // {
//   //   img: img7,
//   //   title: "Basketball",
//   //   author: "@tjdragotta",
//   // },
// ];
//

const SurgicalLab = () => {
  //
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>Surgical And Life Care Support</h2>
        <p>
          Elevate surgical and life care operations with our specialized support
          solutions. Our biomedical engineers ensure the seamless functionality
          and reliability of critical equipment, offering expert installations,
          maintenance, and rapid response services. Focus on patient care with
          confidence, knowing your facilities are in capable hands.
        </p>
        <h2>Our Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg1} alt="alt" />
              <h3>Technology Integration and Support</h3>
            </div>
            <p>
              They can assist in integrating new medical technologies into
              surgical settings, such as robotic surgical systems or advanced
              imaging equipment. This includes installation, training, and
              ongoing technical support to optimize the use of these
              technologies.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg2} alt="alt" />
              <h3>Biomedical Instrumentation</h3>
            </div>
            <p>
              Design, development, and calibration of biomedical instrumentation
              used in surgical procedures, such as monitoring devices, patient
              sensors, and diagnostic equipment.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg3} alt="alt" />
              <h3>Clinical Engineering Services</h3>
            </div>
            <p>
              Providing technical support to clinical staff during surgeries,
              ensuring that equipment operates correctly and troubleshoot any
              issues that may arise.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg4} alt="alt" />
              <h3>Safety and Compliance</h3>
            </div>
            <p>
              Ensuring that all medical equipment meets regulatory standards and
              safety requirements, conducting inspections and audits as
              necessary.
            </p>
          </div>
        </div>
        {/* others */}
        {/* Research and Development: Collaborating with medical professionals to
        develop new medical devices and technologies that improve surgical
        outcomes and patient care.  */}
        {/* Training and Education: Providing training
        programs for healthcare professionals on the use of medical equipment
        and technologies, as well as best practices in equipment maintenance and
        safety. */}
        {/* Consultation Services: Offering expert advice and consultation on
        medical equipment selection, procurement, and utilization strategies to
        healthcare facilities. */}
        {/* Remote Monitoring and Support: Implementing systems for remote
        monitoring of medical equipment performance and providing real-time
        support to healthcare providers. */}
        {/* Quality Assurance: Implementing quality assurance programs to ensure the
        effectiveness and reliability of medical equipment used in surgical
        procedures. */}
        {/*  */}
        <h2>Image Gallery</h2>
        {/* <ImageList sx={{ width: "100%", height: 450 }}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
          <img className="CarouselContainerImg" src={img5} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default SurgicalLab;
