import React from "react";
import "./Home.css";
//
//import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
//

import { Parallax } from "react-scroll-parallax";
//
import Landing from "../Landing/Landing";
import AboutUS from "../AboutUS/AboutUS";
import Services from "../Services/Services";
import Team from "../Team/Team";
import Brands from "../Brands/Brands";
import Partners from "../Partners/Partners";
//import Contact from "../Contact/Contact";
//import Achievement from "../../components/Achievement/Achievement";
import Testimonial from "../../components/Testimonial/Testimonial";
import FAQ from "../../components/FAQ/FAQ";

const Home = () => {
  return (
    <div className="AppHome">
      <Parallax id="home">
        <Landing />
      </Parallax>
      <Parallax id="aboutus">
        <AboutUS />
      </Parallax>
      <Parallax id="service">
        <Services />
      </Parallax>
      <Parallax id="team">
        <Team />
      </Parallax>
      <Parallax id="brand">
        <Brands />
      </Parallax>
      <Parallax id="partner">
        <Partners />
      </Parallax>
      {/* <Parallax>
        <Achievement />
      </Parallax> */}
      <Parallax id="testimonial">
        <Testimonial />
      </Parallax>
      <Parallax id="faq">
        <FAQ />
      </Parallax>
      {/* <Parallax id="contact">
        <Contact />
      </Parallax> */}
    </div>
  );
};

export default Home;
