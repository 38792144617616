import React from "react";
import "./AboutUS.css";
//ant design
import { Carousel } from "antd";
//
//
import TextTransition, { presets } from "react-text-transition";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
// import { Parallax } from "react-scroll-parallax";
//img
// import history_pic from "../../assets/about/history1.jpg";
// import mission_pic from "../../assets/about/mission.jpg";
// import vision_pic from "../../assets/about/vision1.jpg";
// //
//import { ClockCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { Image } from "antd";
//
// import beforeimg1 from "../../assets/about/BEFOR/ARSHO_SPECT.jpg";
// import beforeimg2 from "../../assets/about/BEFOR/ARSHO_SPECT1.jpg";
// import beforeimg3 from "../../assets/about/BEFOR/ARSO_SPECT.jpg";
//import beforeimg4 from "../../assets/about/BEFOR/HANDLING_SERVICE_ISSUE.jpg";
//import beforeimg5 from "../../assets/about/BEFOR/JIGJIGA_DR_OMER_X_RAY_INSTALETION.jpg";
//import beforeimg6 from "../../assets/about/BEFOR/PDC_FLURO_INSTALETION.jpg";
//import beforeimg7 from "../../assets/about/BEFOR/PDC_MRI.jpg";
//import beforeimg8 from "../../assets/about/BEFOR/WELEGA_UNIVER_ITY_X_RAY_INSTALETION.jpg";
//
//import logo from "../../assets/logo.png";
//import plantimg from "../../assets/about/plant.svg";
//import growthimg from "../../assets/about/growth.svg";
//import launchimg from "../../assets/about/launch.svg";
//import comunityimg from "../../assets/about/comunity.svg";
//import milestonesimg from "../../assets/about/milestones.svg";
//import targetimg from "../../assets/about/target.svg";
//import officeIcon from "../../assets/about/location.png";
//ant desig
// import { Popover } from "antd";
//
const TEXTS = [
  "Beginnings",
  "Roots",
  "Origins",
  "Prequel",
  "Journey",
  "Startup",
  "Emerging",
];

// const contentStyle = {
//   height: "160px",
//   color: "#fff",
//   lineHeight: "160px",
//   textAlign: "center",
//   background: "red",
// };
//
// const itemData = [
//   {
//     img: beforeimg3,
//     title: "SPECT",
//     author: "@ARSO",
//     cols: 2,
//   },
//   {
//     img: beforeimg4,
//     title: "HANDLING SERVICE ISSUE",
//     author: "@MTS",
//     cols: 2,
//   },
//   {
//     img: beforeimg5,
//     title: "X-RAY INSTALETION",
//     author: "@JIGJIGA DR OMER",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: beforeimg6,
//     title: "FLURO INSTALETION",
//     author: "@PDC",
//   },
//   {
//     img: beforeimg7,
//     title: "MRI",
//     author: "@PDC",
//   },
//   {
//     img: beforeimg8,
//     title: "X-RAY INSTALETION",
//     author: "@WELEGA UNIVERITY",
//     rows: 2,
//     cols: 2,
//   },
// ];
// //

const AboutUS = () => {
  //
  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  //
  return (
    <div className="AboutUS">
      <div class="hero-image">
        <div class="hero-text">
          <h1>About Us</h1>
        </div>
      </div>
      <div className="beforeImgsDiv">
        <div className="TextTransitionDiv">
          <h2 className="TextTransitionFixed">Our</h2>
          <h2 className="TextTransition">
            <TextTransition springConfig={presets.wobbly}>
              {TEXTS[index % TEXTS.length]}
            </TextTransition>
          </h2>
        </div>

        {/* <ImageList
          className="beforeImgsList"
          sx={{ width: "100%", height: 450 }}
        >
          <ImageListItem key="Subheader" cols={2}>
            <ListSubheader component="div">Before History</ListSubheader>
          </ImageListItem>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                className="beforeImg"
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList> */}
        {/* <Carousel */}
        {/* // className="beforeImgsList"
          className="beforeCarouselContainer"
          arrows
          dotPosition="left"
          infinite={true}
          autoplay={true}
        > */}
        {/* <img src={beforeimg3}></img>
          <img className="beforeImg" src={beforeimg4}></img>
          <img className="beforeImg" src={beforeimg5}></img>
          <img className="beforeImg" src={beforeimg6}></img>
          <img className="beforeImg" src={beforeimg7}></img>
          <img className="beforeImg" src={beforeimg6}></img> */}
        {/* <h1>1</h1>
        </Carousel> */}

        {/* <div className="beforeCarouselContainer"> */}
        {/* <Carousel
          className="beforeCarousel"
          arrows
          dotPosition="left"
          infinite={true}
          autoplay={true}
        >
          <img className="beforeCarouseImg" src={beforeimg4}></img>
          <img className="beforeCarouseImg" src={beforeimg5}></img>
          <img className="beforeCarouseImg" src={beforeimg6}></img>
          <img className="beforeCarouseImg" src={beforeimg7}></img>
          <img className="beforeCarouseImg" src={beforeimg6}></img>
        </Carousel> */}
        <div className="CarouselDiv">
          <>
            <Carousel
              className="beforeCarousel"
              arrows
              dotPosition="left"
              infinite={true}
              autoplay={true}
            >
              <img
                className="beforeCarouseImg"
                src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/beforeimages/myrsa4wd1rl1kxwp8rby"
                alt="Handling Service Issue"
                loading="lazy"
              ></img>
              <img
                className="beforeCarouseImg"
                src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/beforeimages/uhlnfhmik6w4rlf9lm1k"
                alt="Jigiga DR-Omer X-Ray Installation"
                loading="lazy"
              ></img>
              <img
                className="beforeCarouseImg"
                src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/beforeimages/kh1kyeoqd0zw3wdrffg8"
                alt="PDC FLURO Installation"
                loading="lazy"
              ></img>
              <img
                className="beforeCarouseImg"
                src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/beforeimages/ilzxj4l5fdddgegar2gd"
                alt="PDC MRI Installation"
                loading="lazy"
              ></img>
            </Carousel>
          </>
        </div>
        {/* </div> */}
      </div>
      {/* <div className="beforeImgsDiv">
        <ImageList
          className="beforeImgsList"
          sx={{ width: "100%", height: 450 }}
        >
          <ImageListItem key="Subheader" cols={2}>
            <ListSubheader component="div">Before History</ListSubheader>
          </ImageListItem>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                className="beforeImg"
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div> */}
      {/* <div className="AUContent">
        <div className="AUC1Left">
          <h3 className="auch">Our History</h3>
          <pre className="aucpre">
            At Multi-Tech Technology Solution and Trading PLC, our journey began
            with a humble yet ambitious vision: to revolutionize the healthcare
            technology industry. Established in [year], we embarked on a mission
            to deliver cutting-edge solutions that elevate patient care and
            empower healthcare providers worldwide.
          </pre>
        </div>
        <div className="AUC1Right">
          <img
            style={{ width: "400px", height: "400px" }}
            className="Img_AU"
            src={history_pic}
          ></img>
        </div>
      </div> */}
      {/* <div className="AUContent">
        <div className="AUC1Left">
          <img
            style={{ width: "400px", height: "400px" }}
            className="Img_AU"
            src={mission_pic}
          ></img>
        </div>
        <div className="AUC1Right">
          <h3 className="auch">Our Mission</h3>
          <pre className="aucpre">
            At Multi-Tech Technology Solution and Trading PLC, our mission is
            clear: to lead the way in delivering exceptional medical service
            solutions. We provide unmatched expertise in customer consulting,
            precise installations, and proactive maintenance of medical devices.
            Through innovation and dedication, we empower healthcare
            organizations with the technology needed to optimize patient care.
          </pre>
        </div>
      </div> */}
      {/* <div className="AUContent">
        <div className="AUC1Left">
          <h3 className="auch">Our vision</h3>
          <pre className="aucpre">
            Our vision at Multi-Tech Technology Solution and Trading PLC is to
            be the leading force driving transformative advancements in
            healthcare technology. We aim to set new standards of excellence in
            patient care globally, recognized as the premier partner for
            pioneering solutions. With adaptability and innovation at our core,
            we strive to shape the future of healthcare, making a meaningful
            impact on the industry's evolution.
          </pre>
        </div>
        <div className="AUC1Right">
          <img
            style={{ width: "400px", height: "400px" }}
            className="Img_AU"
            src={vision_pic}
          ></img>
        </div>
      </div> */}
      <div className="TimeLine">
        <Timeline mode="alternate">
          <Timeline.Item color="green">
            <Image
              className="ant-image"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/upnunk763crmn26ltlkm"
              alt="Company Logo Image"
              preview={false} // Disable preview for better performance
              loading="lazy"
            />

            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/upnunk763crmn26ltlkm"
              alt="Founding"
              loading="lazy"
            ></img>

            <h3 className="time">2022</h3>
            <h2>Founding</h2>
            <p className="TimelineContent">
              Born from a vision to innovate and drive change, our company was
              founded on the principles of determination and creativity. From
              humble beginnings, we've grown into a dynamic force committed to
              excellence and customer satisfaction. Together, we're shaping a
              brighter future.
            </p>
          </Timeline.Item>

          <Timeline.Item color="green">
            <Image
              className="ant-image"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/wtleyt7xfyzg4qllyxcq"
              alt="launchimg"
              preview={false} // Disable preview for better performance
              loading="lazy"
            />
            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/wtleyt7xfyzg4qllyxcq"
              alt="launchimg"
              loading="lazy"
            ></img>
            <h3 className="time">2023</h3>
            <h2>Service Launches</h2>
            <p className="TimelineContent">
              At Multi Tech Technology Solutions PLC, innovation never stops.
              Our recent service launch marks just the beginning of a journey
              dedicated to exceeding expectations and shaping the future. Stay
              tuned as we continue to evolve and innovate, always striving to
              deliver excellence.
            </p>
          </Timeline.Item>

          <Timeline.Item color="blue">
            <Image
              className="ant-image"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/najijhs1tknjdgqhgac2"
              alt="Founding Image"
              preview={false} // Disable preview for better performance
              loading="lazy"
            />
            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/najijhs1tknjdgqhgac2"
              alt="growthimg"
              loading="lazy"
            ></img>
            <h3 className="time">2024</h3>
            <h2>Company Growth</h2>
            <p className="TimelineContent">
              Absolutely, let's personalize it: "At Bright Horizons, growth is
              not just a goal; it's our journey. From our inception to our
              current achievements, every step reflects our dedication and
              vision. With an unwavering commitment to excellence and
              innovation, we've witnessed remarkable expansion, extending our
              influence and impact. As we embrace the future, we're excited for
              the path ahead, driven by the endless possibilities of tomorrow."
            </p>
          </Timeline.Item>

          {/* <Timeline.Item
            dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
            color="blue"
          >
            <h3>Mission</h3>
            <p>
              To provide innovative digital image solutions that empower
              businesses and organizations to harness the power of visual data
              for strategic insights and impactful outcomes.
            </p>
          </Timeline.Item> */}
          {/* <Timeline.Item color="green">
            <h3>Milestones & Achievements</h3>
            <Image.PreviewGroup>
              <Image
                width={200}
                src="https://media.istockphoto.com/id/1083390204/vector/road-infographics-winding-road-to-success-with-pin-pointers-business-journey-way-progress.jpg?s=612x612&w=0&k=20&c=ZPiT3WEmuWbwGhVkngVnGNJUNEa2NPpwQfGRihOR-OE="
                alt="Milestone 1 Image"
              />
              <Image
                width={200}
                src="https://cdn-icons-png.freepik.com/512/10529/10529846.png"
                alt="Milestone 2 Image"
              />
            </Image.PreviewGroup>
            <p>Reached 100 clients milestone - 20XX</p>
          </Timeline.Item> */}
          <Timeline.Item
            // dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
            color="blue"
          >
            <Image
              className="ant-design"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/koeewh4plpqaqnrq2q36"
              alt="Founding Image"
              preview={false} // Disable preview for better performance
              loading="lazy"
            />
            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/koeewh4plpqaqnrq2q36"
              alt="comunityimg"
              loading="lazy"
            ></img>
            <h2>Community Engagement</h2>
            <p className="TimelineContent">
              Community engagement lies at the heart of our mission. At Bright
              Horizons, we believe in actively participating in and uplifting
              the communities we serve. Through meaningful partnerships,
              volunteer efforts, and initiatives, we strive to make a positive
              impact and foster lasting connections. Together, we're building a
              stronger, more vibrant community for all.
            </p>
          </Timeline.Item>
          <Timeline.Item color="green">
            <Image
              className="ant-image"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/vo8q2phxm14lfsglh5u5"
              alt="Future Outlook Image"
              preview={false}
              loading="lazy"
            />
            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/vo8q2phxm14lfsglh5u5"
              alt="milestonesimg"
              loading="lazy"
            ></img>
            <h2>Major Milestones</h2>
            <p className="TimelineContent">
              Reflecting on our journey, major milestones mark our path at
              Bright Horizons. From our founding to groundbreaking innovations,
              each milestone signifies our commitment to progress and
              excellence. With gratitude for our past achievements and
              excitement for what lies ahead, we continue to celebrate these
              significant moments as we shape the future together.
            </p>
          </Timeline.Item>
          <Timeline.Item
            // dot={
            //   <ClockCircleOutlined
            //     style={{ maxWidth: "10px", height: "auto" }}
            //   />
            // }
            color="blue"
          >
            <Image
              className="ant-image"
              width={300}
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/gpziauzgtdyhew9arndc"
              alt="Future Outlook Image"
              preview={false} // Disable preview for better performance
              loading="lazy"
            />
            <img
              className="react-image"
              src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/Aboutus/timetreeimgs/gpziauzgtdyhew9arndc"
              alt="targetimg"
              loading="lazy"
            ></img>
            <h2>Future Vision</h2>
            <p className="TimelineContent">
              At Bright Horizons, our vision extends far beyond the horizon. We
              envision a future where innovation drives progress, empathy guides
              our actions, and every individual has the opportunity to thrive.
              With a commitment to excellence and a passion for positive change,
              we're dedicated to shaping a brighter tomorrow for generations to
              come.
            </p>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
};

export default AboutUS;
