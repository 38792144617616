import React from "react";
import { useEffect } from "react";
import "./DigitalImageSolution.css";
//ant design
import { Carousel } from "antd";
//
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import ImageListItemBar from "@mui/material/ImageListItemBar";
// import ListSubheader from "@mui/material/ListSubheader";
// import IconButton from "@mui/material/IconButton";
// import InfoIcon from "@mui/icons-material/Info";
//
import ms_thumb from "../../../assets/services/digital_service/head_img.jpg";
// import test_img from "../../../assets/Map_marker.png";
// import maintenance_icon from "../../../assets/services/medical_service/icons/service.png";
// import install_icon from "../../../assets/services/medical_service/icons/install.png";
// import uninstall_icon from "../../../assets/services/medical_service/icons/uninstall.png";
//
import img1 from "../../../assets/services/digital_service/img1.jpg";
import img2 from "../../../assets/services/digital_service/img2.jpg";
import img3 from "../../../assets/services/digital_service/img3.jpg";
import img4 from "../../../assets/services/digital_service/img4.jpg";
import img5 from "../../../assets/services/digital_service/img5.jpg";
import img6 from "../../../assets/services/digital_service/img6.jpg";
import img7 from "../../../assets/services/digital_service/img7.jpg";
import img8 from "../../../assets/services/digital_service/img8.jpg";
import img9 from "../../../assets/services/digital_service/img9.jpg";
import img10 from "../../../assets/services/digital_service/img10.jpg";
import img11 from "../../../assets/services/digital_service/img11.jpg";
//
import simg1 from "../../../assets/services/digital_service/s1.png";
import simg2 from "../../../assets/services/digital_service/s2.png";
import simg3 from "../../../assets/services/digital_service/s3.png";
import simg4 from "../../../assets/services/digital_service/s4.png";
//

// const itemData = [
//   {
//     img: img1,
//     title: "Breakfast",
//     author: "@bkristastucchio",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img2,
//     title: "Burger",
//     author: "@rollelflex_graphy726",
//   },
//   {
//     img: img3,
//     title: "Camera",
//     author: "@helloimnik",
//   },
//   {
//     img: img4,
//     title: "Coffee",
//     author: "@nolanissac",
//     cols: 2,
//   },
//   {
//     img: img5,
//     title: "Hats",
//     author: "@hjrc33",
//     cols: 2,
//   },
//   {
//     img: img6,
//     title: "Honey",
//     author: "@arwinneil",
//     rows: 2,
//     cols: 2,
//     featured: true,
//   },
//   {
//     img: img7,
//     title: "Basketball",
//     author: "@tjdragotta",
//   },
// ];
//

const DigitalImageSolution = () => {
  //to make it start from the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //
  return (
    <div className="SubService">
      <div className="SubServiceThumbDiv">
        <img
          className="ssthumb_img"
          src={ms_thumb}
          alt="Medical Service Thumblin"
        ></img>
      </div>
      <div className="SubServiceContent">
        <h2>Digital Image Solutions</h2>
        <p>
          Unlock the full potential of your digital imagery with our
          cutting-edge Digital Image Solutions. Whether you're capturing,
          storing, or processing images, our tailored services offer a holistic
          approach to meet your diverse needs. From advanced image acquisition
          techniques to robust storage solutions and innovative image processing
          algorithms, we provide the tools and expertise to enhance the quality,
          accessibility, and efficiency of your digital image assets. With a
          commitment to innovation and excellence, we empower businesses and
          organizations to harness the power of visual data for strategic
          insights and impactful outcomes.
        </p>
        <h2>Digital Image Capabilities</h2>
        <div className="SSCapabilities">
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg1} alt="alt" />
              <h3>Medical Imaging Analysis</h3>
            </div>
            <p>
              Using advanced algorithms and software, they can analyze medical
              images such as MRI scans, CT scans, and X-rays to assist in
              diagnosis and treatment planning. This could include segmentation
              of organs or tumors, measurement of anatomical structures, and
              detection of abnormalities
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg2} alt="alt" />
              <h3>Image Processing and Enhancement</h3>
            </div>
            <p>
              They can develop techniques to enhance the quality of medical
              images, such as noise reduction, contrast adjustment, and artifact
              removal, ensuring clearer and more accurate diagnostics.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg3} alt="alt" />
              <h3>Medical Image Registration and Fusion</h3>
            </div>
            <p>
              Integration of multiple imaging modalities (e.g., MRI, PET, CT)
              through registration and fusion techniques, allowing for
              comprehensive visualization and analysis of anatomical and
              functional information.
            </p>
          </div>
          <div className="SSCapabilitie">
            <div className="SSheader">
              <img src={simg4} alt="alt" />
              <h3>Telemedicine and Remote Imaging Solutions</h3>
            </div>
            <p>
              Developing systems for remote viewing and analysis of medical
              images, enabling teleconsultations and remote diagnostics in
              healthcare settings with limited access to specialists.
            </p>
          </div>
        </div>
        {/* Other  */}
        {/* Image-Based Research Support: 
        Providing image analysis services to
        support research in biomedical fields, including cell imaging,
        histopathology, and genetic imaging studies. 
        
        Regulatory Compliance and Quality Assurance: 
        Ensuring that imaging systems and algorithms meet
        regulatory standards (e.g., FDA, CE) for medical devices, and
        implementing quality assurance measures to maintain accuracy and
        reliability. 
        
        Custom Software Development: 
        Designing and implementing
        custom software solutions for specific imaging needs, such as image
        processing pipelines, image management systems, and specialized medical
        image viewers. 
        
        Collaborative Projects and Consulting: 
        
        Collaborating with
        healthcare providers, researchers, and pharmaceutical companies on
        imaging-related projects, offering expertise in image analysis, data
        interpretation, and technology integration. */}
        {/*  */}
        <h2>Image Gallery</h2>
        {/* <div>
          <ImageList sx={{ width: "100%", height: 450 }}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.title}
                  subtitle={item.author}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.title}`}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div> */}
        <Carousel arrows dotPosition="left" infinite={true} autoplay={true}>
          <img className="CarouselContainerImg" src={img1} alt="alt"></img>
          <img className="CarouselContainerImg" src={img2} alt="alt"></img>
          <img className="CarouselContainerImg" src={img3} alt="alt"></img>
          <img className="CarouselContainerImg" src={img4} alt="alt"></img>
          <img className="CarouselContainerImg" src={img5} alt="alt"></img>
          <img className="CarouselContainerImg" src={img6} alt="alt"></img>
          <img className="CarouselContainerImg" src={img7} alt="alt"></img>
          <img className="CarouselContainerImg" src={img8} alt="alt"></img>
          <img className="CarouselContainerImg" src={img9} alt="alt"></img>
          <img className="CarouselContainerImg" src={img10} alt="alt"></img>
          <img className="CarouselContainerImg" src={img11} alt="alt"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default DigitalImageSolution;
