import React from "react";
import "./Landing.css";
//carousel
//import { Component } from "react";
//import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
//images
import bgimg1 from "../../assets/services/medical_service/img1.jpg";
import bgimg2 from "../../assets/landing/img4.jpg";
import bgimg3 from "../../assets/landing/img3.jpg";
/////
const Landing = () => {
  return (
    <div className="Landing">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showThumbs={false}
        stopOnHover={false}
        swipeable={false}
      >
        <div className="carouselDivContainer">
          <img className="carouselIMG" src={bgimg1} alt="carouselIMG" />
          <p
            style={{ width: "90%", height: "90%", opacity: "0.5" }}
            className="legend"
          >
            <div style={{ width: "100%", height: "100%" }} className="Slide">
              <div class="slide-content">
                <h1>Welcome to Multi-Tech Technology Solution PLC</h1>
                <p>
                  Discover cutting-edge solutions across a spectrum of
                  technology sectors. Your trusted partner in innovative
                  technological advancements.
                </p>
                {/* <a href="#services" class="cta-button">
                  Learn More
                </a> */}
              </div>
            </div>
          </p>
        </div>
        <div className="carouselDivContainer">
          <img className="carouselIMG" src={bgimg2} alt="carouselIMG" />
          <p
            style={{ width: "90%", height: "90%", opacity: "0.5" }}
            className="legend"
          >
            <div style={{ width: "100%", height: "100%" }} className="Slide">
              <div class="slide-content">
                <h1> Excellence in Technology</h1>
                <p>
                  At Multi-Tech, excellence is our standard. Explore how we
                  deliver top-tier solutions across diverse technological
                  fields.
                </p>

                {/* <a href="#services" class="cta-button">
                  Learn More
                </a> */}
              </div>
            </div>
          </p>
        </div>

        <div className="carouselDivContainer">
          <img
            className="carouselIMG"
            src="https://res.cloudinary.com/dodzmgyxj/image/upload/f_auto,q_auto/v1/MTTS_PLC/landing/u62oqxa9wqjtrir6ze5u"
            alt="alt"
          />
          <p
            style={{ width: "90%", height: "90%", opacity: "0.5" }}
            className="legend"
          >
            <div style={{ width: "100%", height: "100%" }} className="Slide">
              <div class="slide-content">
                <h1>Pioneering Innovation</h1>
                <p>
                  Innovation is at our core. Experience our latest advancements
                  driving technological evolution globally.
                </p>

                {/* <a href="#services" class="cta-button">
                  Learn More
                </a> */}
              </div>
            </div>
          </p>
        </div>

        <div className="carouselDivContainer">
          <img className="carouselIMG" src={bgimg3} alt="alt" />
          <p
            style={{ width: "90%", height: "90%", opacity: "0.5" }}
            className="legend"
          >
            <div style={{ width: "100%", height: "100%" }} className="Slide">
              <div class="slide-content">
                <h1>Dedication to Quality</h1>
                <p>
                  We are dedicated to delivering quality solutions across all
                  technological domains. Join us in shaping the future of
                  technology.
                </p>

                {/* <a href="#services" class="cta-button">
                  Learn More
                </a> */}
              </div>
            </div>
          </p>
        </div>

        <div className="carouselDivContainer">
          <img className="carouselIMG" src={bgimg3} alt="alt" />
          <p
            style={{ width: "90%", height: "90%", opacity: "0.5" }}
            className="legend"
          >
            <div style={{ width: "100%", height: "100%" }} className="Slide">
              <div class="slide-content">
                <h1>Partnering for Progress</h1>
                <p>
                  Together, let's forge ahead in transforming industries through
                  technology. Partner with us for comprehensive and impactful
                  solutions.
                </p>

                {/* <a href="#services" class="cta-button">
                  Learn More
                </a> */}
              </div>
            </div>
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default Landing;
